<template>
  <div class="container">
    <p class="p1">请在电脑端使用IE浏览器打开以下网址发起网银转账</p>
    <p class="p2">{{url}}</p>
    <van-button
      class="copyBtn"
      color="#ff9800"
      type="primary"
      block
      round
      v-clipboard:copy="url"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      复制链接
    </van-button>
  </div>
</template>

<script>
import '@/assets/js/rem'
export default {
  data(){
    return {
      url:'',
      orderNo:''
    }
  },
  created(){
    let origin
    if (!window.location.origin) {
      origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    } else {
      origin = window.location.origin
    }
    this.url=`${origin}/pay?orderNo=${this.$route.query.orderNo}&sign=${this.$route.query.sign}`
  },
  methods:{
    onCopy () {
      this.$toast("内容已复制到剪切板")
    },
    // 复制失败时的回调函数
    onError () {
      this.$toast("抱歉，复制失败！")
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  margin:0.28rem;
  height: calc(100% - 0.56rem);
  box-shadow: 0px 3px 15px 0px #F0F0F0;
  border-radius: 0.18rem;
  overflow: hidden;
  text-align: center;
  .p1{
    margin-top:1.77rem;
    font-size: 0.26rem;
  }
  .p2{
    color: #929AAB;
    font-size: 0.28rem;
    margin-top:0.3rem;
    padding:0 0.3rem;
    word-break: break-all;
  }
  .copyBtn{
    width: 2.46rem;
    margin:0 auto;
    margin-top:1.24rem;
    // box-shadow: 0px 3px 15px 0px rgba(2, 77, 231, 0.33);
  }
}
</style>